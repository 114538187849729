import Swiper, { Navigation, A11y } from 'swiper';
import { initAfterDOMLoaded } from '../utilities/initAfterDOMLoaded';


let MultiPromoSwiperArgs = {
    modules: [Navigation, A11y],
    slidesPerView: 'auto',
    spaceBetween: 20,
    a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
    paginationElement: 'button',
    keyboard: {
        enabled: true
    },
    navigation: {
    },
    on: {
        reachEnd: function() {
            this.el.classList.remove('shadow-right')
        },
        reachBeginning: function() {
            this.el.classList.remove('shadow-left')
        },
        fromEdge: function() {
            this.el.classList.add('shadow-right')
            this.el.classList.add('shadow-left')
        }
    }
}


initAfterDOMLoaded(initMultiPromoSwiper)


function initMultiPromoSwiper() {
    console.log('multi promo swiper init')
    const swipers = Array.from(document.querySelectorAll('.multipromo-slider-container'))
    if(swipers){
        for(let i = 0; i < swipers.length; i++){
            swipers[i].setAttribute('id', `multipromo-swiper-${i}`)
            swipers[i].querySelector('.multipromo-swiper-button-prev').classList.add(`multipromo-swiper-button-prev-${i}`)
            swipers[i].querySelector('.multipromo-swiper-button-next').classList.add(`multipromo-swiper-button-next-${i}`)
            MultiPromoSwiperArgs.navigation.nextEl = `.multipromo-swiper-button-next-${i}`
            MultiPromoSwiperArgs.navigation.prevEl = `.multipromo-swiper-button-prev-${i}`

            const swiper = new Swiper(`#multipromo-swiper-${i} .swiper`, MultiPromoSwiperArgs)
           
        }
    }
}






  