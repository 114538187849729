//To fix the async error related to babel. Babel has been deprecated
import 'regenerator-runtime/runtime'
import { initAfterDOMLoaded } from '../utilities/initAfterDOMLoaded';

let productInfo = '';
let prodInfoButton;
let prodRegButton;
let inputSerialNumber;

initAfterDOMLoaded(initProdRegistration);

function initProdRegistration() {
    inputSerialNumber = document.querySelector("#serialNumberInput");
    prodRegButton = document.querySelector("#registerProduct");
    prodInfoButton = document.querySelector("#getProductInfoBtn");
    if (prodInfoButton && inputSerialNumber && prodRegButton) {
        inputSerialNumber.addEventListener("keyup", stateHandle);
        prodInfoButton.addEventListener("click", getProductInfo);
        prodRegButton.addEventListener("click", RegisterProduct);
    }
};

async function getProductInfo() {
    const response = await fetch('/ProductRegistration/GetProductInformation?' +
        new URLSearchParams({ serialNumber: document.getElementById('serialNumberInput').value }).toString(),
        {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            }
        })
    productInfo = await response.json();

    renderProductInfo(productInfo);;
}

function stateHandle() {
    if (inputSerialNumber.value.trim() !== "" && inputSerialNumber.value.length > 0) {
        prodInfoButton.removeAttribute("disabled"); //button remains disabled
    } else {
        prodInfoButton.setAttribute("disabled", true);
    }
}

function renderProductInfo(productInfo) {
    if (productInfo.ProductName == null) {
        document.querySelector("#SerialNumberError").style.display = 'block';
        document.querySelector("#registration-error-blurb").style.display = 'block';
        document.querySelector("#registration-contact-us").style.display = 'block';
        var serialNumberError = document.getElementById("product-serial-number");
        inputSerialNumber = document.getElementById("serialNumberInput");
        serialNumberError.classList.add("error");
        inputSerialNumber.classList.add("error");
    }
    else {
        var serialNumber = document.getElementById("product-serial-number");
        inputSerialNumber = document.getElementById("serialNumberInput");
        serialNumber.classList.remove("error");
        inputSerialNumber.classList.remove("error");
        document.querySelector("#SerialNumberError").style.display = 'none';
        document.querySelector("#registration-error-blurb").style.display = 'none';
        document.querySelector("#registration-contact-us").style.display = 'none';
        const modal = document.querySelector("#product-info-modal");
        const imageContainer = document.querySelector("#productImage")
        document.querySelector("#product-name").innerText = productInfo.ProductName;
        document.querySelector("#serial-number").innerText = inputSerialNumber.value;
        const productImage = `<img src = "${productInfo.ImageUri}" alt = "${productInfo.ProductName}"/>`
        imageContainer.innerHTML = productImage;
        modal.style.display = 'block'
    }
}

async function RegisterProduct() {
    location.href = '/ProductRegistration/RegisterProduct' + "?serialNumber=" + inputSerialNumber.value;
}