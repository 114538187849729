function initAssetTracking() {
    const assetsToTrack = document.querySelectorAll('[data-analytic-track="true"]')
    if (assetsToTrack.length > 0) {
        assetsToTrack.forEach(asset => {
            asset.addEventListener('click', function reportAssetData(e) {
                let data = asset.dataset
                delete data.analyticTrack

                // Convert DOMStringMap to plain object
                let dataObj = {};
                for (let key in data) {
                    dataObj[key] = data[key];
                }

                // capture selectedFilters if inside service listing widget
                const isInsideServiceListingWidget = document.getElementById('service-listing-form')
                if (isInsideServiceListingWidget) {
                    dataObj.selectedFilters = getSelectedFilterValues()
                }

                const dataPayload = {
                    event: 'assetInteraction',
                    asset: dataObj
                }
                
                dataLayer.push(dataPayload)
                console.log('Asset data sent to dataLayer.', dataPayload)
            })
        })
    }
}

// for interfaces with filters
function getSelectedFilterValues() {
    const selectedFilters = document.querySelectorAll('input[type = "checkbox"]:checked')
    const selectedValues = []
    selectedFilters.forEach(filter => {
        selectedValues.push(filter.value)
    })
    // split the filters into key-value pairs
    const selectedValuesObj = {}
    selectedValues.forEach(value => {
        const [key, val] = value.split('|')
        if (selectedValuesObj[key]) {
            selectedValuesObj[key].push(val)
            return
        }
        selectedValuesObj[key] = [val]
    })
   
    return selectedValuesObj
}

export { initAssetTracking }