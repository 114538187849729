import { initAssetTracking} from './analytic-asset-reporting.js' 
!function ($) {
	$(function() {
		$('#service-notice-close').on('click', function(e) {
			e.preventDefault();
			$(this).parent().remove();
		});
		initAssetTracking();
	});
}(window.jQuery);
