// Note this code is probably not needed once we change the script to type="module" or defer the script, which requires code splitting in our bundle.

export const initAfterDOMLoaded = (callback) => {
    if (document.readyState !== 'loading') {
        console.log(`document ready ${callback.name}`);
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', function listener() {
            console.log('document was not ready in asset analytics');
            callback();
            document.removeEventListener('DOMContentLoaded', listener);
        });
    }
};